import { registerAsyncElement } from "src/lib/async-elements";

const EmMailIPSelectorComponentFactory = () => import(
    /* webpackChunkName: 'mail-ip-selector' */
    /* prefetch: false */
    "src/custom-elements/mail-ip-selector"
);

const EmDownloadFormComponentFactory = () => import(
    /* webpackChunkName: 'webinare-form' */
    /* prefetch: false */
    "src/custom-elements/download-form"
);

const EmWebinaireFormComponentFactory = () => import(
    /* webpackChunkName: 'webinare-form' */
    /* prefetch: false */
    "src/custom-elements/webinare-form"
);

const EmMailSubSelectorPanelComponentFactory = () => import(
    /* webpackChunkName: 'mail-sub-selector-panel' */
    /* prefetch: false */
    "src/custom-elements/mail-sub-selector-panel"
);

const EmTestComponentFactory = () => import(
    /* webpackChunkName: 'custom-elements-test' */
    /* prefetch: false */
    "src/custom-elements/test"
);

const EmVentesPriveesComponentFactory = () => import(
    /* webpackChunkName: 'custom-elements-ventes-privees' */
    /* prefetch: false */
    "src/custom-elements/ventes-privees"
);

const EmNewsletterComponentFactory = () => import(
    /* webpackChunkName: 'custom-elements-newsletter' */
    /* prefetch: false */
    "src/custom-elements/newsletter"
);

const EmAccountCreationRequestComponentFactory = () => import(
    /* webpackChunkName: 'custom-elements/account-creation-request' */
    /* prefetch: false */
    "src/custom-elements/account-creation-request"
);

const EmOuvertureCompteM2FComponentFactory = () => import(
    /* webpackChunkName: 'custom-elements/ouverture-compte-m2f' */
    /* prefetch: false */
    "src/custom-elements/ouverture-compte-m2f"
);

const EmMailCreditsSelectorComponentFactory = () => import(
    /* webpackChunkName: 'custom-elements/mail-credits-selector' */
    /* prefetch: false */
    "src/custom-elements/mail-credits-selector"
);

const EmMailSubSelectorComponentFactory = () => import(
    /* webpackChunkName: 'custom-elements/mail-sub-selector' */
    /* prefetch: false */
    "src/custom-elements/mail-sub-selector"
);

const EmProductListComponentFactory = () => import(
    /* webpackChunkName: 'custom-elements/product-list' */
    /* prefetch: false */
    "src/custom-elements/product-list"
);

const EmProductCardComponentFactory = () => import(
    /* webpackChunkName: 'custom-elements/product-card' */
    /* prefetch: false */
    "src/custom-elements/product-card"
);

const EmCoachingFormComponentFactory = () => import(
    /* webpackChunkName: 'custom-elements/product-card' */
    /* prefetch: false */
    "src/custom-elements/coaching-form"
);

const EmLotteryFormComponentFactory = () => import(
    /* webpackChunkName: 'custom-elements/lottery-form' */
    /* prefetch: false */
    "src/custom-elements/lottery-form"
);

console.log("Registering CustomElements");
registerAsyncElement("em-test", EmTestComponentFactory);
registerAsyncElement("em-ventes-privees", EmVentesPriveesComponentFactory);
registerAsyncElement("em-newsletter", EmNewsletterComponentFactory);
registerAsyncElement("em-account-creation-request", EmAccountCreationRequestComponentFactory);
registerAsyncElement("em-ouverture-compte", EmAccountCreationRequestComponentFactory); // composant de l'ancien formulaire pointant vers le nouveau
registerAsyncElement("em-ouverture-compte-m2f", EmOuvertureCompteM2FComponentFactory);
registerAsyncElement("em-mail-credits-selector", EmMailCreditsSelectorComponentFactory);
registerAsyncElement("em-product-list", EmProductListComponentFactory);
registerAsyncElement("em-product-card", EmProductCardComponentFactory);
registerAsyncElement("em-mail-sub-selector", EmMailSubSelectorComponentFactory);
registerAsyncElement("em-mail-ip-selector", EmMailIPSelectorComponentFactory);
registerAsyncElement("em-mail-sub-selector-panel", EmMailSubSelectorPanelComponentFactory);
registerAsyncElement("em-coaching-form", EmCoachingFormComponentFactory);
registerAsyncElement("em-lottery-form", EmLotteryFormComponentFactory);
registerAsyncElement("em-webinare-form", EmWebinaireFormComponentFactory);
registerAsyncElement("em-download-form", EmDownloadFormComponentFactory);