import type { Root } from "react-dom/client";

async function LoadReact () {
    const { default: React } = (await import(
        /* prefetch: false */
        "react"
    ));
    const { createRoot } = (await import(
        /* prefetch: false */
        "react-dom/client"
    ));
    return { React, createRoot };
}

export type $ExtractPropsType<T extends React.ComponentType<any>> = T extends React.ComponentType<infer P> ? P : never;

const roots = new WeakMap<HTMLElement, Root>();

export function registerAsyncElement<T extends React.ComponentType<any>> (tag: string, factory: () => Promise<{ default: T }>) {
    class EmCustomElement extends HTMLElement {
        async connectedCallback () {
            const { React, createRoot } = await LoadReact();
            const props = Object.fromEntries(Object.values(this.attributes).map(attribute => [attribute.name, attribute.value] as const)) as $ExtractPropsType<T>;
            const Component = React.lazy(factory);
            const root = createRoot(this);
            roots.set(this, root);
            root.render(<div className="em-component-root">
                <React.Suspense fallback={<>Chargement en cours ...</>}>
                    <Component {...props} />
                </React.Suspense>
            </div>);
        }

        async disconnectedCallback () {
            const root = roots.get(this);
            if (root) {
                root.unmount();
                roots.delete(this);
            }
        }
    }

    customElements.define(tag, EmCustomElement);

    console.log(`Registered CustomElement: ${tag}`);

    return EmCustomElement;
}